import React from "react";
import { FiGithub, FiExternalLink, FiFolder } from "react-icons/fi";
import pharmIQ from "../../assets/images/pharmIQ.png";
import lifestore from "../../assets/images/lifestore.png";
import arinnovation from "../../assets/images/arinnovation.png";
import ogapharmacy from "../../assets/images/ogapharmacy.png";
import "./Work.css";

const Work = () => {
  return (
    <section className="work-section" id="work">
      <div className="work-content">
        <div className="work-me">
          <h2>
            <span className="counter">03.</span>Work
          </h2>
        </div>
        <div className="work-list">
          <div className="work-item">
            <div className="project-image">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://arinnovations.io/"
              >
                <img
                  src={arinnovation}
                  alt="arinnovation"
                  width="450"
                  height={300}
                  style={{ "margin-top": "20px" }}
                />
              </a>
            </div>
            <div className="project-description">
              <span className="featured">Feature project</span>
              <h3 className="project-title">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://arinnovations.io"
                >
                  ARINNOVATION
                </a>
              </h3>
              <p>
                Arinnovation uses AR technology to create, manage and deploy 3D
                & AR of your products seamlessly everywhere your customers shop
                at scale. With this project, we engineered the world’s most
                advanced, full-body Photogrammetry Rapid Capture System 'The
                Hedron'. HEDRON enables our team to create High-fidelity 3D
                models in lightening speed.
              </p>
              <div className="project-links">
                <ul>
                  <li>React</li>
                  <li>Redux</li>
                  <li>AR</li>
                  <li>Node.js</li>
                  <li>MongoDB</li>
                </ul>
              </div>
              {/* <div className="project-details">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="project-details-external"
                  href="https://arinnovations.io/#"
                >
                  <FiExternalLink size={20} />
                </a>
              </div> */}
            </div>
          </div>
          <div className="work-item left">
            <div className="project-description-left">
              <span className="featured">Feature project</span>
              <h3 className="project-title">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://ogapharmacy.com/"
                >
                  OGApharmacy
                </a>
              </h3>
              <p>
                OGApharmacy is an easy-to-use online platform for healthcare
                providers in Nigeria to access quality & affordable medicines.
                It uses technology to transform access to quality and affordable
                primary healthcare.
              </p>
              <div className="project-links">
                <ul>
                  <li>React</li>
                  <li>Redux</li>
                  <li>Node.js</li>
                  <li>SQL</li>
                </ul>
              </div>
              {/* <div className="project-details">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="project-details-external"
                  href="https://ogapharmacy.com/"
                >
                  <FiExternalLink size={20} />
                </a>
              </div> */}
            </div>
            <div className="project-image">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://ogapharmacy.com/"
              >
                <img
                  src={ogapharmacy}
                  alt="Space traveller's Hub"
                  width="450"
                  height={300}
                  style={{ "margin-top": "20px" }}
                />
              </a>
            </div>
          </div>
          <div className="work-item">
            <div className="project-image">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://ogapharmacy.com/sign-up/"
              >
                <img src={pharmIQ} alt="zero thirst" width="450" />
              </a>
            </div>
            <div className="project-description">
              <span className="featured">Feature project</span>
              <h3 className="project-title">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://ogapharmacy.com/sign-up/"
                >
                  PharmIQ
                </a>
              </h3>
              <p>
                PharmIQ provides real-time insights into the daily operations of
                a pharmacy.It integrates with the pharmacy's various systems,
                such as the point-of-sale, inventory management, and dispensing
                systems. PharmIQ uses secure APIs to connect to the pharmacy's
                various systems and ensures data privacy and security through
                encryption and access control mechanisms.
              </p>
              <div className="project-links">
                <ul>
                  <li>React</li>
                  <li>Python</li>
                  <li>Redux</li>
                  <li>Django</li>
                  <li>GraphQL</li>
                </ul>
              </div>
              {/* <div className="project-details">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="project-details-external"
                  href="https://ogapharmacy.com/sign-up/"
                >
                  <FiExternalLink size={20} />
                </a>
              </div> */}
            </div>
          </div>
          <div className="work-item left">
            <div className="project-description-left">
              <span className="featured">Feature project</span>
              <h3 className="project-title">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://lifestoreshealthcare.com/"
                >
                  Lifestores HealthCare
                </a>
              </h3>
              <p>
                Lifestores manages a chain of affordable pharmacies and a
                pioneering chronic disease management program.It helps in
                democratizing access to quality and affordable primary
                healthcare in Africa. We partner with trusted manufacturers and
                distributors, and pass on their discounts to you.
              </p>
              <div className="project-links">
                <ul>
                  <li>Javascript</li>
                  <li>React</li>
                  <li>Node.js</li>
                  <li>Jest</li>
                </ul>
              </div>
              {/* <div className="project-details">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="project-details-external"
                  href="https://lifestoreshealthcare.com/"
                >
                  <FiExternalLink size={20} />
                </a>
              </div> */}
            </div>
            <div className="project-image">
              <a
                target="_blank"
                rel="noreferrer"
                className="project-details-external"
                href="https://lifestoreshealthcare.com/"
              >
                <img
                  src={lifestore}
                  alt="lifestore"
                  width="450"
                  height={300}
                  style={{ margin: "20px" }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="more-projects">
          <h2 className="more-projects-title">Other noteworthy projects</h2>
          <ul className="projects-section">
            <li className="project-card">
              <div className="project-card-content">
                <div className="project-card-top">
                  <FiFolder size={25} className="folder" />
                  <div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://github.com/apeunit/unit-a-landing-page"
                    >
                      <FiGithub size={25} className="project-external-link" />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://unita.apeunit.com/"
                    >
                      <FiExternalLink
                        size={25}
                        className="project-external-link"
                      />
                    </a>
                  </div>
                </div>
                <h3 className="project-card-title">Unit A</h3>
                <div className="project-card-description">
                  <p>
                    A joint-force top between African & European developers &
                    organizations working in blockchain technology
                  </p>
                </div>
                <div className="project-card-stacks">
                  <span>Next.js</span>
                  <span>Tailwind</span>
                  <span>Node.js</span>
                </div>
              </div>
            </li>
            <li className="project-card">
              <div className="project-card-content">
                <div>
                  <div className="project-card-top">
                    <FiFolder size={25} className="folder" />
                    <div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://github.com/Ntwali-Josue/book-appointment-front-end"
                      >
                        <FiGithub size={25} className="project-external-link" />
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://work-spaces.netlify.app/"
                      >
                        <FiExternalLink
                          size={25}
                          className="project-external-link"
                        />
                      </a>
                    </div>
                  </div>
                  <h3 className="project-card-title">Work Spaces</h3>
                  <div className="project-card-description">
                    <p>
                      A website used to attract and connect an energetic
                      community of positive and open-minded business thinkers
                    </p>
                  </div>
                </div>
                <div className="project-card-stacks">
                  <span>React</span>
                  <span>Node.js</span>
                  <span>Rails</span>
                  <span>Postgresql</span>
                </div>
              </div>
            </li>
            <li className="project-card">
              <div className="project-card-content">
                <div className="project-card-top">
                  <FiFolder size={25} className="folder" />
                  <div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://github.com/Ntwali-Josue/Budget-App"
                    >
                      <FiGithub size={25} className="project-external-link" />
                    </a>
                  </div>
                </div>
                <h3 className="project-card-title">Budget App</h3>
                <div className="project-card-description">
                  <p>
                    A web app, where users can manage their budget, with a list
                    of transactions to see how much money is spent.
                  </p>
                </div>
                <div className="project-card-stacks">
                  <span>React</span>
                  <span>Rails</span>
                  <span>RSpec</span>
                  <span>Postgresql</span>
                </div>
              </div>
            </li>
            <li className="project-card">
              <div className="project-card-content">
                <div className="project-card-top">
                  <FiFolder size={25} className="folder" />
                  <div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://github.com/Ntwali-Josue/RecipeApp"
                    >
                      <FiGithub size={25} className="project-external-link" />
                    </a>
                  </div>
                </div>
                <h3 className="project-card-title">Recipe App</h3>
                <div className="project-card-description">
                  <p>
                    Recipe App is a web application that allows users to create,
                    and delete recipes, foods, and ingredients.
                  </p>
                </div>
                <div className="project-card-stacks">
                  <span>React</span>
                  <span>Rails</span>
                  <span>Postgresql</span>
                </div>
              </div>
            </li>
            <li className="project-card">
              <div className="project-card-content">
                <div className="project-card-top">
                  <FiFolder size={25} className="folder" />
                  <div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://github.com/Ntwali-Josue/Blog-app"
                    >
                      <FiGithub size={25} className="project-external-link" />
                    </a>
                  </div>
                </div>
                <h3 className="project-card-title">Blog App</h3>
                <div className="project-card-description">
                  <p>
                    Blog App is a CRUD web application that is built with MVC
                    architecture, to allows users to manage their posts.
                  </p>
                </div>
                <div className="project-card-stacks">
                  <span>Rails</span>
                  <span>RSpec</span>
                  <span>Postgresql</span>
                </div>
              </div>
            </li>
            <li className="project-card">
              <div className="project-card-content">
                <div className="project-card-top">
                  <FiFolder size={25} className="folder" />
                  <div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://github.com/Ntwali-Josue/School-Library"
                    >
                      <FiGithub size={25} className="project-external-link" />
                    </a>
                  </div>
                </div>
                <h3 className="project-card-title">School library</h3>
                <div className="project-card-description">
                  <p>
                    Simple UI console app built for allowing Users to manage
                    library for adding books, students, teacher.
                  </p>
                </div>
                <div className="project-card-stacks">
                  <span>Ruby</span>
                  <span>RSpec</span>
                  <span>Postgresql</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Work;
