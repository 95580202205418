import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { BiCalendar } from "react-icons/bi";
import { GiDiamonds } from "react-icons/gi";
import "./Experience.css";

const Experience = () => {
  return (
    <section className="experience-section" id="experience">
      <div className="experience-content">
        <div className="title-section">
          <h2>
            <span className="counter">02.</span>Experience
          </h2>
        </div>
        <div className="experience-list">
          <Tabs defaultIndex={0}>
            <TabList>
              <Tab selected={true}>Kitech</Tab>
              {/* <Tab>Locar</Tab>
              <Tab>Keetela</Tab> */}
              <Tab>Andela</Tab>
              <Tab>Lifestore Pharmacy</Tab>
            </TabList>
            <div>
              <TabPanel>
                <div className="experience-description">
                  <div className="experience-title">
                    <h3>
                      Full stack developer ~{" "}
                      <a
                        href="https://kitech.rw/"
                        target="_blank"
                        rel="noreferrer noopenner"
                        className="company"
                      >
                        Kitech
                      </a>
                    </h3>
                    <span className="date">
                      <BiCalendar size={15} /> Jun,2022 – present
                    </span>
                  </div>
                  <div className="experience-details">
                    <p>
                      <GiDiamonds size={10} className="diamond" />
                    </p>
                    <p>
                      Improved functionalities and pipeline integration by
                      designing and implementing cross-platform frameworks.
                    </p>
                  </div>
                  <div className="experience-details">
                    <p>
                      <GiDiamonds size={10} className="diamond" />
                    </p>
                    <p>
                      Performed regression and system-level testing to verify
                      quality software quality and function before release.
                    </p>
                  </div>
                  <div className="experience-details">
                    <p>
                      <GiDiamonds size={10} className="diamond" />
                    </p>
                    <p>
                      Debugged and provided quick, efficient solutions for
                      highly critical bugs in major product releases.
                    </p>
                  </div>
                  <div className="experience-details">
                    <p>
                      <GiDiamonds size={10} className="diamond" />
                    </p>
                    <p>
                      Analyzed code defects and failures and presented code
                      refactors and design fixes
                    </p>
                  </div>
                </div>
              </TabPanel>
              {/* <TabPanel>
                <div className="experience-description">
                  <div className="experience-title">
                    <h3>
                      Full stack developer ~{" "}
                      <a
                        href="https://keetela.com/"
                        target="_blank"
                        rel="noreferrer noopenner"
                        className="company"
                      >
                        Keetela
                      </a>
                    </h3>
                    <span className="date">
                      <BiCalendar size={15} /> July,2020 – July,2021
                    </span>
                  </div>
                  <div className="experience-details">
                    <p>
                      <GiDiamonds size={10} className="diamond" />
                    </p>
                    <p>
                      Responsible for developing a platform to improve the
                      current Trusella financial e-commerce services offered to
                      employees which raised 30% of the revenue.
                    </p>
                  </div>
                  <div className="experience-details">
                    <p>
                      <GiDiamonds size={10} className="diamond" />
                    </p>
                    <p>
                      Develop backend API for the official Keetela tutoring
                      platform.
                    </p>
                  </div>
                  <div className="experience-details">
                    <p>
                      <GiDiamonds size={10} className="diamond" />
                    </p>
                    <p>
                      Debugged and provided quick, efficient solutions for
                      highly critical bugs in major product releases.
                    </p>
                  </div>
                  <div className="experience-details">
                    <p>
                      <GiDiamonds size={10} className="diamond" />
                    </p>
                    <p>
                      Collaborated with project managers to select, ambitious
                      and realistic coding milestones on pre-release software
                      project management
                    </p>
                  </div>
                </div>
              </TabPanel> */}
              <TabPanel>
                <div className="experience-description">
                  <div className="experience-title">
                    <h3>
                      Technical Team Lead ~{" "}
                      <a
                        href="https://rwanda.andela.com/"
                        target="_blank"
                        rel="noreferrer noopenner"
                        className="company"
                      >
                        {" "}
                        Andela
                      </a>
                    </h3>
                    <span className="date">
                      <BiCalendar size={15} /> April 2020 – June 2021
                    </span>
                  </div>
                  <div className="experience-details">
                    <p>
                      <GiDiamonds size={10} className="diamond" />
                    </p>
                    <p>
                      Ensured tight cooperation with other engineers, designers,
                      and customers through active listening, systematic
                      communication, and leadership skills.
                    </p>
                  </div>
                  <div className="experience-details">
                    <p>
                      <GiDiamonds size={10} className="diamond" />
                    </p>
                    <p>
                      Write modern, performant, and robust code for a diverse
                      array of client and internal projects.
                    </p>
                  </div>
                  <div className="experience-details">
                    <p>
                      <GiDiamonds size={10} className="diamond" />
                    </p>
                    <p>
                      Improved functionalities and pipeline integration by
                      designing and implementing cross-platform frameworks.
                    </p>
                  </div>
                  <div className="experience-details">
                    <p>
                      <GiDiamonds size={10} className="diamond" />
                    </p>
                    <p>
                      Performed regression and system-level testing to verify
                      quality software quality and function before release.
                    </p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="experience-description">
                  <div className="experience-title">
                    <h3>
                      Full stack developer ~{" "}
                      <a
                        href="https://lifestoreshealthcare.com/"
                        target="_blank"
                        rel="noreferrer noopenner"
                        className="company"
                      >
                        Lifestore Pharmacy
                      </a>
                    </h3>
                    <span className="date">
                      <BiCalendar size={15} /> Jan 2021 -April 2021
                    </span>
                  </div>
                  <div className="experience-details">
                    <p>
                      <GiDiamonds size={10} className="diamond" />
                    </p>
                    <p>
                      Developed and shipped highly interactive web applications
                      for PharmIQ.
                    </p>
                  </div>
                  <div className="experience-details">
                    <p>
                      <GiDiamonds size={10} className="diamond" />
                    </p>
                    <p>
                      Interfaced with user experience designers and other
                      developers to ensure thoughtful and coherent user
                      experiences across lifestyle health pharmacy’s web
                      layouts.
                    </p>
                  </div>
                  <div className="experience-details">
                    <p>
                      <GiDiamonds size={10} className="diamond" />
                    </p>
                    <p>
                      Communicate and collaborate with multi-disciplinary teams
                      of engineers, designers, pharmacists, clients, and
                      stakeholders on a daily basis.
                    </p>
                  </div>
                  <div className="experience-details">
                    <p>
                      <GiDiamonds size={10} className="diamond" />
                    </p>
                    <p>
                      Created endpoints and functionality for the new dashboard
                      on the back end and engineered logic on front end pages to
                      gather and display the data.
                    </p>
                  </div>
                </div>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </section>
  );
};

export default Experience;
